exports.components = {
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-testing-tsx": () => import("./../../../src/pages/testing.tsx" /* webpackChunkName: "component---src-pages-testing-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/BlogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-customer-story-tsx": () => import("./../../../src/templates/CustomerStory.tsx" /* webpackChunkName: "component---src-templates-customer-story-tsx" */),
  "component---src-templates-download-thank-you-tsx": () => import("./../../../src/templates/DownloadThankYou.tsx" /* webpackChunkName: "component---src-templates-download-thank-you-tsx" */),
  "component---src-templates-download-tsx": () => import("./../../../src/templates/Download.tsx" /* webpackChunkName: "component---src-templates-download-tsx" */),
  "component---src-templates-generic-page-tsx": () => import("./../../../src/templates/GenericPage.tsx" /* webpackChunkName: "component---src-templates-generic-page-tsx" */),
  "component---src-templates-industry-tsx": () => import("./../../../src/templates/Industry.tsx" /* webpackChunkName: "component---src-templates-industry-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */)
}

