import { datadogRum } from '@datadog/browser-rum';
import { GatsbyBrowser } from 'gatsby';
import './src/styles/global.css';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  window.startDataDog = () => {
    const env = process.env.GATSBY_ACTIVE_ENV;

    if (env === 'production' && !window.dataDogHasBeenInit) {
      window.dataDogHasBeenInit = true;

      const version = process.env.GATSBY_APP_VERSION;

      const initParams: Parameters<typeof datadogRum.init>[0] = {
        applicationId: 'adc197ff-cead-47c3-ab5a-28a5f9bd4b27',
        clientToken: 'pub1279026aa665e4ead1170504ccfc46f0',
        site: 'datadoghq.com',
        service: 'marketing',
        env,
        version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        defaultPrivacyLevel: 'allow',
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
      };

      datadogRum.init(initParams);
    }

    datadogRum.startSessionReplayRecording();
  };
};
